<template>
  <div id="returnOrder">
    <el-dialog
      :title="returnOrderFormTitle"
      width="680px"
      :visible.sync="returnOrderDialogVisible"
      :close-on-click-modal="false"
      @close="returnOrderDialogClose"
    >
      <el-form
        ref="returnOrderFormRef"
        :model="returnOrderForm"
        :rules="returnOrderFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="物料名称" prop="materialName">
              <el-input v-model="returnOrderForm.materialName" placeholder="请输入物料名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批号" prop="materialBatchNo">
              <el-input v-model="returnOrderForm.materialBatchNo" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格/装量" prop="spec">
              <el-input v-model="returnOrderForm.spec" placeholder="请输入规格/装量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单位" prop="unit">
              <el-input v-model="returnOrderForm.unit" placeholder="请输入单位" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量" prop="quantity">
              <el-input v-model="returnOrderForm.quantity" placeholder="请输入数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="来源" prop="source">
              <el-input v-model="returnOrderForm.source" placeholder="请输入来源" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="原因" prop="reason">
              <el-input v-model="returnOrderForm.reason" placeholder="请输入原因" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="returnOrderForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="经手人" prop="dealer">
              <el-input v-model="returnOrderForm.dealer" placeholder="请输入经手人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="handledDate">
              <el-date-picker v-model="returnOrderForm.handledDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仓库管理员" prop="warehouse">
              <el-input v-model="returnOrderForm.warehouse" placeholder="请输入仓库管理员" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="warehouseDate">
              <el-date-picker v-model="returnOrderForm.warehouseDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="质检员" prop="inspector">
              <el-input v-model="returnOrderForm.inspector" placeholder="请输入质检员" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="inspectionDate">
              <el-date-picker v-model="returnOrderForm.inspectionDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="returnOrderDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="returnOrderFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="物料名称">
        <el-input v-model="searchForm.materialName" placeholder="请输入物料名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="returnOrderPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="materialName" label="物料名称" />
      <el-table-column prop="materialBatchNo" label="批号" />
      <el-table-column prop="spec" label="规格/装量" />
      <el-table-column prop="unit" label="单位" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="source" label="来源" />
      <el-table-column prop="reason" label="原因" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="dealer" label="经手人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.handledDate">{{ scope.row.handledDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="warehouse" label="仓库管理员" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.warehouseDate">{{ scope.row.warehouseDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="inspector" label="质检员" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectionDate">{{ scope.row.inspectionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="returnOrderPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addReturnOrder, deleteReturnOrder, updateReturnOrder, selectReturnOrderInfo, selectReturnOrderList } from '@/api/storage/returnOrder'

export default {
  data () {
    return {
      returnOrderDialogVisible: false,
      returnOrderFormTitle: '',
      returnOrderForm: {
        id: '',
        materialName: '',
        materialBatchNo: '',
        spec: '',
        unit: '',
        quantity: '',
        source: '',
        reason: '',
        remarks: '',
        dealer: '',
        handledDate: '',
        warehouse: '',
        warehouseDate: '',
        inspector: '',
        inspectionDate: ''
      },
      returnOrderFormRules: {
        materialName: [{ required: true, message: '物料名称不能为空', trigger: ['blur', 'change']}]
      },
      returnOrderPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        materialName: ''
      }
    }
  },
  created () {
    selectReturnOrderList(this.searchForm).then(res => {
      this.returnOrderPage = res
    })
  },
  methods: {
    returnOrderDialogClose () {
      this.$refs.returnOrderFormRef.resetFields()
    },
    returnOrderFormSubmit () {
      if (this.returnOrderFormTitle === '退库单详情') {
        this.returnOrderDialogVisible = false
        return
      }
      this.$refs.returnOrderFormRef.validate(async valid => {
        if (valid) {
          if (this.returnOrderFormTitle === '新增退库单') {
            await addReturnOrder(this.returnOrderForm)
          } else if (this.returnOrderFormTitle === '修改退库单') {
            await updateReturnOrder(this.returnOrderForm)
          }
          this.returnOrderPage = await selectReturnOrderList(this.searchForm)
          this.returnOrderDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.returnOrderFormTitle = '新增退库单'
      this.returnOrderDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteReturnOrder(row.id)
        if (this.returnOrderPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.returnOrderPage = await selectReturnOrderList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.returnOrderFormTitle = '修改退库单'
      this.returnOrderDialogVisible = true
      this.selectReturnOrderInfoById(row.id)
    },
    handleInfo (index, row) {
      this.returnOrderFormTitle = '退库单详情'
      this.returnOrderDialogVisible = true
      this.selectReturnOrderInfoById(row.id)
    },
    selectReturnOrderInfoById (id) {
      selectReturnOrderInfo(id).then(res => {
        this.returnOrderForm.id = res.id
        this.returnOrderForm.materialName = res.materialName
        this.returnOrderForm.materialBatchNo = res.materialBatchNo
        this.returnOrderForm.spec = res.spec
        this.returnOrderForm.unit = res.unit
        this.returnOrderForm.quantity = res.quantity
        this.returnOrderForm.source = res.source
        this.returnOrderForm.reason = res.reason
        this.returnOrderForm.remarks = res.remarks
        this.returnOrderForm.dealer = res.dealer
        this.returnOrderForm.handledDate = res.handledDate
        this.returnOrderForm.warehouse = res.warehouse
        this.returnOrderForm.warehouseDate = res.warehouseDate
        this.returnOrderForm.inspector = res.inspector
        this.returnOrderForm.inspectionDate = res.inspectionDate
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectReturnOrderList(this.searchForm).then(res => {
        this.returnOrderPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectReturnOrderList(this.searchForm).then(res => {
        this.returnOrderPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectReturnOrderList(this.searchForm).then(res => {
        this.returnOrderPage = res
      })
    }
  }
}
</script>

<style>
</style>
