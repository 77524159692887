import axios from '@/common/axios'
import qs from 'qs'

export function addReturnOrder (data) {
  return axios({
    method: 'post',
    url: '/Storage/return/add',
    data: qs.stringify(data)
  })
}

export function deleteReturnOrder (id) {
  return axios({
    method: 'delete',
    url: '/Storage/return/delete/' + id
  })
}

export function updateReturnOrder (data) {
  return axios({
    method: 'put',
    url: '/Storage/return/update',
    data: qs.stringify(data)
  })
}

export function selectReturnOrderInfo (id) {
  return axios({
    method: 'get',
    url: '/Storage/return/info/' + id
  })
}

export function selectReturnOrderList (query) {
  return axios({
    method: 'get',
    url: '/Storage/return/list',
    params: query
  })
}
